import React from "react";
import "../Css/Navbar.css";
import "../Css/Responsive.css";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import logo from "../Images/logo.png";
import NavDropdown from "react-bootstrap/NavDropdown";
const Navbar1 = () => {
  return (
    <div>
      <section className="top-bar-style2">
        <div className="top-style2 ">
          <div className="top-style2-left">
            <ul>
              <li>+91 9769420637 / 9082060072</li>
              <li>kehainteriollp@gmail.com</li>
              <li>Mon - Sat: 9am to 6pm</li>
            </ul>
          </div>
          <div className="top-style2-right">
            <ul className="topbar-social-links">
              <li>
                {/* <a href="#"> */}
                <i className="fa fa-facebook" aria-hidden="true" />
                {/* </a> */}
              </li>
              <li>
                {/* <a href="#"> */}
                <i className="fa fa-twitter" aria-hidden="true" />
                {/* </a> */}
              </li>
              <li>
                {/* <a href="#"> */}
                <i className="fa fa-instagram" aria-hidden="true" />
                {/* </a> */}
              </li>
            </ul>
          </div>
        </div>
      </section>

      <Navbar expand="lg" collapseOnSelect>
        <Container>
          <Navbar.Brand href="/" className="my-logo">
            <img className="logo-two" src={logo} alt="logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav" className="">
            <Nav className="me-auto ">
              <Nav.Link>
                <Link to="/" className="nav-link">
                  Home
                </Link>
              </Nav.Link>
              <Nav.Link>
                <NavDropdown title="About Us" id="basic-nav-dropdown">
                  <NavDropdown.Item>
                    <Link to="/top-interior-designers">Mision</Link>
                  </NavDropdown.Item>
                  <NavDropdown.Item >
                  <Link to="/interior-contractor-and-designer">Vision</Link>
                  </NavDropdown.Item>
                  <NavDropdown.Item >
                  <Link to="/best-luxury-interior-designer">Value</Link>
                  </NavDropdown.Item>
                </NavDropdown>
              </Nav.Link>
              <Nav.Link href="/Client">
                <Link to="/interior-company" className="nav-link">
                  Clients
                </Link>
              </Nav.Link>
              <Nav.Link>
                <NavDropdown title="Projects" id="basic-nav-dropdown">
                  <NavDropdown.Item >
                  <Link to="/best-interior-designers-in-mumbai">Completed Projects</Link>
                  </NavDropdown.Item>
                  <NavDropdown.Item >
                  <Link to="/residential-interior-designers">Ongoing Projects</Link>
                  </NavDropdown.Item>
                  <NavDropdown.Item >
                  <Link to="/best-luxury-interior-designers">Upcoming Projects</Link>
                  </NavDropdown.Item>
                </NavDropdown>
                {/* <Link to="/Projects" className="nav-link"></Link> */}
              </Nav.Link>

              <Nav.Link href="/Services/interior-designing-company">
                <Link to="/Services/interior-designing-company" className="nav-link">
                  Work Stages
                </Link>
              </Nav.Link>
              <Nav.Link href="/Contact">
                <Link to="/office-interior-designer-in-mumbai" className="nav-link">
                  Contact us
                </Link>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
          <div className="button d-lg-block d-none">
            <a className="btn-one" href="tel:+919769420637">
              Get a Quote
              <span className="flaticon-next" />
            </a>
          </div>
        </Container>
      </Navbar>
    </div>
  );
};

export default Navbar1;
