import Deloitte1 from '../Images/Deloitte site Thane/d1.jpg';
import Deloitte2 from '../Images/Deloitte site Thane/d2.jpg';
import Deloitte3 from '../Images/Deloitte site Thane/d3.jpg';
import Deloitte4 from '../Images/Deloitte site Thane/d4.jpg';
import Deloitte5 from '../Images/Deloitte site Thane/d5.jpg';
import Deloitte6 from '../Images/Deloitte site Thane/d6.jpg';

//J b Nagar 
import JbNagar1 from '../Images/J b Nagar/jb1.jpg';
import JbNagar2 from '../Images/J b Nagar/jb2.jpg';
import JbNagar3 from '../Images/J b Nagar/jb3.jpg';
import JbNagar4 from '../Images/J b Nagar/jb4.jpg';
import JbNagar5 from '../Images/J b Nagar/jb5.jpg';
import JbNagar6 from '../Images/J b Nagar/jb6.jpg';
import JbNagar7 from '../Images/J b Nagar/jb7.jpg';
import JbNagar8 from '../Images/J b Nagar/jb8.jpg';
import JbNagar9 from '../Images/J b Nagar/jb9.jpg';
import JbNagar10 from '../Images/J b Nagar/jb10.jpg';

// keva
import Keva1 from '../Images/keva/k1.jpg';
import Keva2 from '../Images/keva/k2.jpg';
import Keva3 from '../Images/keva/k3.jpg';
import Keva4 from '../Images/keva/k4.jpg';
import Keva5 from '../Images/keva/k5.jpg';
import Keva6 from '../Images/keva/k6.jpg';
import Keva7 from '../Images/keva/k7.jpg';
import Keva8 from '../Images/keva/k8.jpg';
import Keva9 from '../Images/keva/k9.jpg';
import Keva10 from '../Images/keva/k10.jpg';

import Indigrid2 from '../Images/indigrid  Kalina/i2.jpg';
import Indigrid3 from '../Images/indigrid  Kalina/i3.jpg';
import Indigrid4 from '../Images/indigrid  Kalina/i4.jpg';
import Indigrid5 from '../Images/indigrid  Kalina/i5.jpg';
import Indigrid6 from '../Images/indigrid  Kalina/i6.jpg';
import Indigrid7 from '../Images/indigrid  Kalina/i7.jpg';
import Indigrid8 from '../Images/indigrid  Kalina/i8.jpg';
import Indigrid9 from '../Images/indigrid  Kalina/i9.jpg';
import Indigrid10 from '../Images/indigrid  Kalina/i10.jpg';
import Indigrid11 from '../Images/indigrid  Kalina/i11.jpg';

import Nielsen1 from '../Images/Nielsen/n1.jpg';
import Nielsen2 from '../Images/Nielsen/n2.jpg';
import Nielsen3 from '../Images/Nielsen/n3.jpg';
import Nielsen4 from '../Images/Nielsen/n4.jpg';
import Nielsen5 from '../Images/Nielsen/n5.jpg';
import Nielsen6 from '../Images/Nielsen/n6.jpg';
import Nielsen7 from '../Images/Nielsen/n7.jpg';
import Nielsen8 from '../Images/Nielsen/n8.jpg';
import Nielsen9 from '../Images/Nielsen/n9.jpg';
import Nielsen10 from '../Images/Nielsen/n10.jpg';



import Uti1 from '../Images/UTI tower BKC/u1.jpg';
import Uti2 from '../Images/UTI tower BKC/u2.jpg';
import Uti3 from '../Images/UTI tower BKC/u3.jpg';
import Uti4 from '../Images/UTI tower BKC/u4.jpg';
import Uti5 from '../Images/UTI tower BKC/u5.jpg';
import Uti6 from '../Images/UTI tower BKC/u6.jpg';
import Uti7 from '../Images/UTI tower BKC/u7.jpg';
import Uti8 from '../Images/UTI tower BKC/u8.jpg';
import Uti9 from '../Images/UTI tower BKC/u9.jpg';
import Uti10 from '../Images/UTI tower BKC/u10.jpg';
import Uti11 from '../Images/UTI tower BKC/u11.jpg';
import Uti12 from '../Images/UTI tower BKC/u12.jpg';


import conn2 from "../Images/connectwell/s1.jpg";
import conn3 from "../Images/connectwell/s2.jpg";
import conn4 from "../Images/connectwell/s3.jpg";
import conn5 from "../Images/connectwell/s4.jpg";
import conn6 from "../Images/connectwell/s5.jpg";


const GalleryData = [

    
    {
        id:1,
        Category:"Deloitte",
        Types:"Office Design",
        EImage:Deloitte1,
        EventName:"Deloitte",
        Location:"Thane"
    },
    {
        Category:"Deloitte",
        Types:"Office Design",
        EImage:Deloitte2,
        EventName:"Deloitte",
        Location:"Thane"
    },
    {
        Category:"Deloitte",
        Types:"Office Design",
        EImage:Deloitte3,
        EventName:"Deloitte",
        Location:"Thane"
    },
    {
        Category:"Deloitte",
        Types:"Office Design",
        EImage:Deloitte4,
        EventName:"Deloitte",
        Location:"Thane"
    },
    {
        Category:"Deloitte",
        Types:"Office Design",
        EImage:Deloitte5,
        EventName:"Deloitte",
        Location:"Thane"
    },
    {
        Category:"Deloitte",
        Types:"Office Design",
        EImage:Deloitte6,
        EventName:"Deloitte",
        Location:"Thane"
    },


    {
        id:2,
        Category:"Fleet Management",
        Types:"modal design",
        EImage:JbNagar1,
        EventName:"Fleet Management System Pvt Ltd",
        Location:"J b Nagar",
    },

    {
        Category:"Fleet Management",
        Types:"modal design",
        EImage:JbNagar2,
        EventName:"Fleet Management System Pvt Ltd",
        Location:"J b Nagar",
    },
    {
        Category:"Fleet Management",
        Types:"modal design",
        EImage:JbNagar3,
        EventName:"Fleet Management System Pvt Ltd",
        Location:"J b Nagar",
    },
    {
        Category:"Fleet Management",
        Types:"modal design",
        EImage:JbNagar4,
        EventName:"Fleet Management System Pvt Ltd",
        Location:"J b Nagar",
    },
    {
        Category:"Fleet Management",
        Types:"modal design",
        EImage:JbNagar5,
        EventName:"Fleet Management System Pvt Ltd",
        Location:"J b Nagar",
    },
    {
        Category:"Fleet Management",
        Types:"modal design",
        EImage:JbNagar6,
        EventName:"Fleet Management System Pvt Ltd",
        Location:"J b Nagar",
    },
    {
        Category:"Fleet Management",
        Types:"modal design",
        EImage:JbNagar7,
        EventName:"Fleet Management System Pvt Ltd",
        Location:"J b Nagar",
    },
    {
        Category:"Fleet Management",
        Types:"modal design",
        EImage:JbNagar8,
        EventName:"Fleet Management System Pvt Ltd",
        Location:"Jb Nagar Andheri East"
    },
    {
        Category:"Fleet Management",
        Types:"modal design",
        EImage:JbNagar9,
        EventName:"Fleet Management System Pvt Ltd",
        Location:"Jb Nagar Andheri East"
    },
    {
        Category:"Fleet Management",
        Types:"modal design",
        EImage:JbNagar10,
        EventName:"Fleet Management System Pvt Ltd",
        Location:"Jb Nagar Andheri East"
    },



    {
        id:3,
        Category:"Keva",
        Types:"modal design",
        EImage:Keva1,
        EventName:"Keva",
        Location:"MIDC Andheri",
    },

    {
        id:3,
        Category:"Keva",
        Types:"modal design",
        EImage:Keva2,
        EventName:"Keva",
        Location:"MIDC Andheri",
    },
    {
        Category:"Keva",
        Types:"modal design",
        EImage:Keva3,
        EventName:"Keva",
        Location:"MIDC Andheri",
    },
    {
        id:3,
        Category:"Keva",
        Types:"modal design",
        EImage:Keva4,
        EventName:"Keva",
        Location:"MIDC Andheri",
    },
    {
        id:3,
        Category:"Keva",
        Types:"modal design",
        EImage:Keva5,
        EventName:"Keva",
        Location:"MIDC Andheri",
    },
    {
        id:3,
        Category:"Keva",
        Types:"modal design",
        EImage:Keva6,
        EventName:"Keva",
        Location:"MIDC Andheri",
    },
    {
        id:3,
        Category:"Keva",
        Types:"modal design",
        EImage:Keva7,
        EventName:"Keva",
        Location:"MIDC Andheri",
    },
    {
        id:3,
        Category:"Keva",
        Types:"modal design",
        EImage:Keva8,
        EventName:"Keva",
        Location:"MIDC Andheri",
    },
    {
        id:3,
        Category:"Keva",
        Types:"modal design",
        EImage:Keva9,
        EventName:"Keva",
        Location:"MIDC Andheri",
    },
    {
        id:3,
        Category:"Keva",
        Types:"modal design",
        EImage:Keva10,
        EventName:"Keva",
        Location:"MIDC Andheri",
    },


    {
        id:4,
        Category:"Indigrid",
        Types:"modal design",
        EImage:Indigrid11,
        EventName:"Indigrid",
        Location:'Kalina'
    },
    {
        Category:"Indigrid",
        Types:"modal design",
        EImage:Indigrid2,
        EventName:"Indigrid",
        Location:'Kalina'
    },
    {
        Category:"Indigrid",
        Types:"modal design",
        EImage:Indigrid3,
        EventName:"Indigrid",
        Location:'Kalina'
    },
    {
        Category:"Indigrid",
        Types:"modal design",
        EImage:Indigrid4,
        EventName:"Indigrid",
        Location:'Kalina'
    },
    {
        Category:"Indigrid",
        Types:"modal design",
        EImage:Indigrid5,
        EventName:"Indigrid",
        Location:'Kalina'
    },
    {
        Category:"Indigrid",
        Types:"modal design",
        EImage:Indigrid6,
        EventName:"Indigrid",
        Location:'Kalina'
    },
    {
        Category:"Indigrid",
        Types:"modal design",
        EImage:Indigrid7,
        EventName:"Indigrid",
        Location:'Kalina'
    },
    {
        Category:"Indigrid",
        Types:"modal design",
        EImage:Indigrid8,
        EventName:"Indigrid",
        Location:'Kalina'
    },
    {
        Category:"Indigrid",
        Types:"modal design",
        EImage:Indigrid9,
        EventName:"Indigrid",
        Location:'Kalina'
    },
    {
        Category:"Indigrid",
        Types:"modal design",
        EImage:Indigrid10,
        EventName:"Indigrid",
        Location:'Kalina'
    },



    {
        id:5,
        Category:"Nielsen",
        Types:"modal design",
        EImage:Nielsen1,
        EventName:"Nielsen",
        Location:'Pune'
    },
    {
        Category:"Nielsen",
        Types:"modal design",
        EImage:Nielsen2,
        EventName:"Nielsen",
        Location:'Pune'
    },
    {
        Category:"Nielsen",
        Types:"modal design",
        EImage:Nielsen3,
        EventName:"Nielsen",
        Location:'Pune'
    },
    {
        Category:"Nielsen",
        Types:"modal design",
        EImage:Nielsen4,
        EventName:"Nielsen",
        Location:'Pune'
    },
    {
        Category:"Nielsen",
        Types:"modal design",
        EImage:Nielsen5,
        EventName:"Nielsen",
        Location:'Pune'
    },
    {
        Category:"Nielsen",
        Types:"modal design",
        EImage:Nielsen6,
        EventName:"Nielsen",
        Location:'Pune'
    },
    {
        Category:"Nielsen",
        Types:"modal design",
        EImage:Nielsen7,
        EventName:"Nielsen",
        Location:'Pune'
    },
    {
        Category:"Nielsen",
        Types:"modal design",
        EImage:Nielsen8,
        EventName:"Nielsen",
        Location:'Pune'
    },
    {
        Category:"Nielsen",
        Types:"modal design",
        EImage:Nielsen9,
        EventName:"Nielsen",
        Location:'Pune'
    },
    {
        Category:"Nielsen",
        Types:"modal design",
        EImage:Nielsen10,
        EventName:"Nielsen",
        Location:'Pune'
    },


    {
        id:6,
        Category:"UTI Tower",
        Types:"modal design",
        EImage:Uti1,
        EventName:"UTI Tower",
        Location:'BKC'
    },
    {
        Category:"UTI Tower",
        Types:"modal design",
        EImage:Uti2,
        EventName:"UTI Tower",
        Location:'BKC'
    },
    {
        Category:"UTI Tower",
        Types:"modal design",
        EImage:Uti3,
        EventName:"UTI Tower",
        Location:'BKC'
    },
    {
        Category:"UTI Tower",
        Types:"modal design",
        EImage:Uti4,

        EventName:"UTI Tower",
        Location:'BKC'
    },
    {
        Category:"UTI Tower",
        Types:"modal design",
        EImage:Uti5,
        EventName:"UTI Tower",
        Location:'BKC'
    },
    {
        Category:"UTI Tower",
        Types:"modal design",
        EImage:Uti6,
        EventName:"UTI Tower",
        Location:'BKC'
    },
    {
        Category:"UTI Tower",
        Types:"modal design",
        EImage:Uti7,
        EventName:"UTI Tower",
        Location:'BKC'
    },
    {
        Category:"UTI Tower",
        Types:"modal design",
        EImage:Uti8,
        EventName:"UTI Tower",
        Location:'BKC'
    },
    {
        Category:"UTI Tower",
        Types:"modal design",
        EImage:Uti9,
        EventName:"UTI Tower",
        Location:'BKC'
    },
    {
        Category:"UTI Tower",
        Types:"modal design",
        EImage:Uti10,
        EventName:"UTI Tower",
        Location:'BKC'
    },
    {
        Category:"UTI Tower",
        Types:"modal design",
        EImage:Uti11,
        EventName:"UTI Tower",
        Location:'BKC'
    },
    {
        Category:"UTI Tower",
        Types:"modal design",
        EImage:Uti12,
        EventName:"UTI Tower",
        Location:'BKC'
    },
    {
        id:7,
        Category:"Connectwell",
        Types:"modal design",
        EImage:conn2,
        EventName:"Connectwell",
        Location:'Dombivali'
    },
    {
        Category:"Connectwell",
        Types:"modal design",
        EImage:conn3,
        EventName:"Connectwell",
        Location:'Dombivali'
    },
    {
        Category:"Connectwell",
        Types:"modal design",
        EImage:conn4,
        EventName:"Connectwell",
        Location:'Dombivali'
    },
    {
        Category:"Connectwell",
        Types:"modal design",
        EImage:conn5,
        EventName:"Connectwell",
        Location:'Dombivali'
    },
    {
        Category:"Connectwell",
        Types:"modal design",
        EImage:conn6,
        EventName:"Connectwell",
        Location:'Dombivali'
    },
    
]

export default GalleryData;