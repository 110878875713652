import React from "react";
import "../Css/Contact.css";
import contact from "../Images/contact-details-bg.jpg";
import Footer from "../Component/Footer.js";
import person from "../Images/contact.png";
import { MetaTags } from "react-meta-tags";

const Contact = () => {
  return (
    <div>
      {/* ========= Sco ========== */}

      <MetaTags>
        <title>
        Contact Us - Keha Interio LLP Company
        </title>
        <meta
          name="description"
          content="Take the first step towards an exceptional interior transformation. Contact keha interio llp today for a consultation and let us bring your dreams to life!"
        />
        <meta
          name="google-site-verification"
          content="lQRenlykQgkhp3tuKDb9sjxgGzGVRUiKnbEwyj16bw0"
        />
        <meta
          property="og:title"
          content=" Sundar Farm Resort Karjat : Unwind in Luxury"
        />
        <meta
          property="og:description"
          content="Take the first step towards an exceptional interior transformation. Contact keha interio llp today for a consultation and let us bring your dreams to life!"
        />
        <meta
          name="keywords"
          content="Interior company in Mumbai ,interior designer in mumbai ,top interior designers in mumbai ,
      best interior designers in mumbai ,residential interior designers in mumbai ,
      office interior designer in mumbai ,bungalow interior design ,office interior designer ,
      residential interior designers ,interior design firms ,interior company ,
      interior designing company ,best interior designing company ,best luxury interior designers ,
      best villa interior design"
        />
        <link rel="canonical" href="https://kehainterio.com/office-interior-designer-in-mumbai" />
      </MetaTags>

      {/* ======== Sco end ======= */}
      <section
        className="breadcrumb-area style2 contact-page"
        style={{ backgroundImage: `url(${contact})` }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="inner-content-box clearfix">
                <div className="title-s2 text-center">
                  <span>Contact Us</span>
                  <h1>Get In Touch With Us</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*Start  Contact Address Box*/}
      <section className="contact-address-area">
        <div className="container">
          <div className="row">
            {/*Start Single Contact Address Box*/}
            <div className="col-xl-4 col-lg-4">
              <div className="single-contact-address-box">
                <span className="icon-global" />
                <h3>Visit Our Office</h3>
                <p>
                  Juhu Post Office,Ground Floor-3,Rupal Apartment,Samarth Ramdas
                  Marg,10th road ,JVPD scheme,Vile
                  parle(w),Suburban,Mumbai-400049
                </p>
              </div>
            </div>
            {/*End Single Contact Address Box*/}
            {/*Start Single Contact Address Box*/}
            <div className="col-xl-4 col-lg-4">
              <div className="single-contact-address-box">
                <span className="icon-support1" />
                <h3>Call Us</h3>
                <p>
                  +91-9769420637 <br />
                  +91- 9082060072
                  <br />
                  <span>Available:24 X 7</span>
                </p>
              </div>
            </div>
            {/*End Single Contact Address Box*/}
            {/*Start Single Contact Address Box*/}
            <div className="col-xl-4 col-lg-4">
              <div className="single-contact-address-box">
                <span className="icon-shipping-and-delivery" />
                <h3>Mail Us</h3>
                <p>
                  kehainteriollp@gmail.com
                  <br /> kehainteriollp@gmail.com
                </p>
              </div>
            </div>
            {/*End Single Contact Address Box*/}
          </div>
        </div>
      </section>
      {/* ------------------------contact form-------------------------- */}
      <section className="contact-info-area">
        <div className="container">
          <div className="row">
            <div className="col-xl-7 col-lg-6 col-md-12 col-sm-12">
              <div className="contact-form">
                <div className="sec-title with-text">
                  <p>Drop Us a Line</p>
                  <div className="title">
                    Send Your <span>Message</span>
                  </div>
                  <p className="bottom-text">
                    If you have question or would like more information on our
                    works, Please complete the form and we’ll aim get back to
                    you with in 24 hours.
                  </p>
                </div>
                <div className="inner-box">
                  <form
                    id="contact-form"
                    name="contact_form"
                    className="default-form"
                    action="inc/sendmail.php"
                    method="post"
                    noValidate="novalidate"
                  >
                    <div className="row">
                      <div className="col-md-6">
                        <div className="input-box">
                          <input
                            type="text"
                            name="form_name"
                            defaultValue=""
                            placeholder="First Name"
                            required=""
                            aria-required="true"
                          />
                          <div className="icon">
                            <i className="fa fa-user" aria-hidden="true" />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-box">
                          <input
                            type="text"
                            name="form_name"
                            defaultValue=""
                            placeholder="Last Name"
                            required=""
                            aria-required="true"
                          />
                          <div className="icon">
                            <i className="fa fa-user" aria-hidden="true" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="input-box">
                          <select
                            className="selectmenu"
                            id="ui-id-1"
                            style={{ display: "none" }}
                          >
                            <option selected="selected">Enquire About</option>
                            <option>Concept Designs</option>
                            <option>Project Designs</option>
                            <option>Make Overs</option>
                            <option>Consulting</option>
                            <option>Glass &amp; Wrought</option>
                            <option>Space Planning</option>
                          </select>
                          <span
                            className="ui-selectmenu-button ui-widget ui-state-default ui-corner-all"
                            tabIndex={0}
                            id="ui-id-1-button"
                            role="combobox"
                            aria-expanded="false"
                            aria-autocomplete="list"
                            aria-owns="ui-id-1-menu"
                            aria-haspopup="true"
                            style={{ width: 141 }}
                          >
                            <span className="ui-icon ui-icon-triangle-1-s" />
                            <span className="ui-selectmenu-text">
                              Enquire About
                            </span>
                          </span>
                          <div className="icon">
                            <i className="fa fa-question" aria-hidden="true" />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-box">
                          <input
                            type="text"
                            name="form_phone"
                            defaultValue=""
                            placeholder="Phone"
                          />
                          <div className="icon">
                            <i className="fa fa-phone" aria-hidden="true" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="input-box">
                          <input
                            type="email"
                            name="form_email"
                            defaultValue=""
                            placeholder="Email Address"
                            required=""
                            aria-required="true"
                          />
                          <div className="icon">
                            <i className="fa fa-envelope" aria-hidden="true" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="input-box">
                          <textarea
                            name="form_message"
                            placeholder="Your Message..."
                            required=""
                            aria-required="true"
                            defaultValue={""}
                          />
                          <div className="icon envelop">
                            <i className="fa fa-comment" aria-hidden="true" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="button-box">
                          <input
                            id="form_botcheck"
                            name="form_botcheck"
                            className="form-control"
                            type="hidden"
                            defaultValue=""
                          />
                          <button
                            className="btn-one"
                            type="submit"
                            data-loading-text="Please wait..."
                          >
                            Send Your Message
                            <span className="flaticon-next" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-5 col-md-12 col-sm-12 ">
              <div className="contact-form-image-box">
                <img src={person} alt="Awesome" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Contact;
