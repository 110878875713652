import React from 'react'
import { HashRouter, BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './Pages/Home';
import Services from './Pages/Services';
import Navbar1 from './Component/Navbar1';
import Projects from './Pages/Projects';
import Contact from './Pages/Contact';
import AboutMission from './Pages/AboutMission';
import Vision from './Pages/Vision';
import Value from './Pages/Value';
import Completedproject from './Pages/Completedproject';
import Ongoingproject from './Pages/Ongoingproject';
import Upcomingproject from './Pages/Upcomingproject';
import Client from "./Pages/Client";


const App = () => {
  return (
    <div>
       <BrowserRouter>
    <Navbar1/>
      <Routes>
         <Route path='/' element={<Home/>}/> 
         <Route path='/office-interior-designer-in-mumbai' element={<Contact/>} />
         <Route path='/Projects/:name' element={<Projects/>} />
         <Route path='/Services/interior-designing-company' element={<Services/>} />
         <Route path='/top-interior-designers' element={<AboutMission/>} />
         <Route path='/interior-contractor-and-designer' element={<Vision/>} />
         <Route path='/best-luxury-interior-designer' element={<Value/>} />
         <Route path='/best-interior-designers-in-mumbai' element={<Completedproject/>} />
         <Route path='/residential-interior-designers' element={<Ongoingproject/>} />
         <Route path='/best-luxury-interior-designers' element={<Upcomingproject/>} />
         <Route path='/interior-company' element={<Client/>} />
      </Routes>
    </BrowserRouter>
    </div>
  )
}

export default App
