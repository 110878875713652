import React, { useState } from "react";
import "../Css/Project.css";
import GalleryData from "../Component/GalleryData";
import axis from "../Images/axis.png";

// import v1 from '../Images/v2-1.jpg';
// import v2 from '../Images/v2-2.jpg';
// import v3 from '../Images/v2-3.jpg';
// import resi from '../Images/gallery/guestroom.jpg';
// import bunglaw from '../Images/gallery/bunglaw.jpg';
// import office1 from '../Images/gallery/office1.jpg';
// import office2 from '../Images/gallery/office2.jpg';
// import office3 from '../Images/gallery/office3.jpg';
import Tabs from "./Tabs";
// import Items from "./Items";
import about2 from "../Images/breadcrumb-bg.jpg";
import { useLocation, useParams } from "react-router-dom";
// import office4 from '../Images/gallery/office1.jpg';
import Footer from "../Component/Footer.js";
const Projects = ({}) => {
  const {name} = useParams();
  console.log(name)

  return (
    <div>
      <section
        className="breadcrumb-area"
        style={{ backgroundImage: `url(${about2})` }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="inner-content clearfix">
                <div className="title">
                  <h1>
                    Experienced
                    <br /> Contractor and designer
                  </h1>
                </div>
                {/* <div className="breadcrumb-menu float-right">
            <ul className="clearfix">
              <li>
                <a href="index.html">Home</a>
              </li>
              <li className="active">Account</li>
            </ul>
          </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="main-project-area">
        <div className="container">
          {/* <Items data={data} /> */}
          {/* <ul className="project-filter post-filter has-dynamic-filters-counter">
            <li data-filter=".filter-item" className="active">
              <span className="filter-text">
                All Projects<span className="count">9</span>
              </span>
            </li>
            <li data-filter=".mod">
              <span className="filter-text">
                Modern<span className="count">6</span>
              </span>
            </li>
            <li data-filter=".contem">
              <span className="filter-text">
                Contemporary<span className="count">3</span>
              </span>
            </li>
            <li data-filter=".trad">
              <span className="filter-text">
                Traditional<span className="count">3</span>
              </span>
            </li>
            <li data-filter=".ret">
              <span className="filter-text">
                Retreat<span className="count">6</span>
              </span>
            </li>
          </ul> */}
          <div className="row">
            {GalleryData.filter((valueData) => valueData.Category === name).map((value) => (
                  <div className="col-lg-4 col-md-6 col-12 trad" >
                    <div className="single-project-style4">
                      <div className="img-holder">
                        <div className="inner">
                          <img src={value.EImage} alt="" />
                          <div className="overlay-box">
                            <div className="box">
                              <div className="link">
                                <span className="icon-out" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="overlay-content">
                          <div className="title">
                            <span>{value.Location}</span>
                            <h3>{value.EventName}</h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
              )
            )}
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Projects;
