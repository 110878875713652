import React from "react";
import "../Css/Footer.css";
import logo from '../Images/logo.png';
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
const Footer = () => {
  return (
    <div>
      <footer className="footer-area style4">
        <div className="container">
          <div className="row">
            {/*Start single footer widget*/}
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
              <div className="single-footer-widget marbtm50-s4">
                <div className="our-info-box">
                  <div className="footer-logo">
                    <a href="index.html">
                      <img
                        src={logo}
                        alt="Awesome Logo"
                      />
                    </a>
                  </div>
                  <div className="text">
                    <p>
                     Keha Interio LLP
                    </p>
                  </div>
                 
                </div>
              </div>
            </div>
            {/*End single footer widget*/}
            {/*Start single footer widget*/}
            <div className="col-xl-4 col-lg-5 col-md-6 col-sm-12">
              <div className="single-footer-widget s4">
                <div className="title-style2">
                  <h3>Usefull Links</h3>
                </div>
                <div className="usefull-links d-flex">
                  <ul className="float-left">
                    <li>
                      <Link to="/top-interior-designers">About us</Link>
                    </li>
                    <li>
                    <Link to="/best-interior-designers-in-mumbai">Projects</Link>
                    </li>
                    <li>
                    <Link to="/Services/interior-designing-company">Work stages</Link>
                    </li>
                    {/* <li>
                    <Link to="/residential-interior-designers">Recent Projects</Link>
                    </li> */}
                  
                  </ul>
                  <ul className="borders-left">
                    {/* <li>
                      <a href="#">News</a>
                    </li> */}
                    {/* <li>
                    <HashLink to="/#serve">Services</HashLink>
                    </li> */}
                    <li>
                    <HashLink to="/interior-company">Clients</HashLink>
                    </li>
                    <li>
                    <Link to="/residential-interior-designers">Recent Projects</Link>
                    </li>
                    {/* <li>
                    <HashLink to="/#connect">Get Quote</HashLink>
                    </li> */}
                    {/* <li>
                      <a href="#">Privacy Policy</a>
                    </li> */}
                    <li>
                      <a href="/office-interior-designer-in-mumbai">Contact Us</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/*End single footer widget*/}
            {/*Start single footer widget*/}
            <div className="col-xl-4 col-lg-3 col-md-12 col-sm-12">
              <div className="single-footer-widget pdtop50-s4">
          <div className="title-style2">
            <h3>Address</h3>
          </div>
    
           
            <div className="text">
              <p>
              Juhu Post Office,Ground Floor-3,Rupal Apartment,Samarth Ramdas Marg,10th road ,JVPD scheme,Vile parle(w),Suburban,Mumbai-400049
              </p>
            </div>
            <div className="title-style2">
            <h3>Contact</h3>
          </div>
    
           
            <div className="text">
              <p>
              9769420637 / 9082060072 </p>
            </div>
            {/* <div className="follow-us-social-links  ">
                    <span>Follw Us On:</span>
                    <ul className="d-flex">
                      <li>
                        <a href="#">Facebook</a>
                      </li>
                      <li>
                        <a href="#">Twitter</a>
                      </li>
                      <li>
                        <a href="#">Instagram</a>
                      </li>
                    </ul>
                  </div> */}
          {/* </div> */}
        </div>
            </div>
            {/*End single footer widget*/}
          </div>
        </div>
      </footer>
      <section className="footer-bottom-area style3">
        <div className="container">
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
              <div className="copyright-text text-center">
                <p>
                  © 2023-2024 All Rights Reserved by <a href="#">Keha Interio LLP</a>&nbsp;|&nbsp;
                  Designed By <a href="https://skdm.in/">Shree Krishna Digital Marketing</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Footer;
