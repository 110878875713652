import React from "react";
import "../Css/Client.css";
import about2 from "../Images/breadcrumb-bg.jpg";
import Footer from "../Component/Footer.js";
import Clientimages6 from "../Images/brands/bearing.jpg";
import Clientimages7 from "../Images/brands/deloit.jpg";
import Clientimages8 from "../Images/brands/8.jpg";
import Clientimages9 from "../Images/brands/9.jpg";
import Clientimages10 from "../Images/brands/added/D-decor.png";
import Clientimages11 from "../Images/brands/added/brook.jpg";
import Clientimages12 from "../Images/brands/added/jt.jpg";
import Clientimages13 from "../Images/brands/added/kalyani.png";
import Clientimages14 from "../Images/brands/added/matrix.png";
import nsci from "../Images/brands/5.jpg";
import indo from "../Images/brands/3 (1).jpg";
import intelnet from "../Images/brands/7.png";
import { MetaTags } from "react-meta-tags";

const Client = () => {
  return (
    <>
      {/* ========= Sco ========== */}

      <MetaTags>
        <title>
          Exquisite Office Interior Designer in Mumbai - Keha Interio LLP
        </title>
        <meta
          name="description"
          content="Elevate Your Workspace with Mumbai's Premier Office Interior Designer. Experience Unparalleled Style & Functionality. Book a Consultation Now!"
        />
        <meta
          name="google-site-verification"
          content="lQRenlykQgkhp3tuKDb9sjxgGzGVRUiKnbEwyj16bw0"
        />
        <meta
          property="og:title"
          content="Exquisite Office Interior Designer in Mumbai - Keha Interio LLP"
        />
        <meta
          property="og:description"
          content="Discover a hidden gem at Sundar Farm Resort Karjat. Unwind amidst lush green landscapes, relish delectable cuisine yourself in the tranquility of nature."
        />
        <meta
          name="keywords"
          content="Interior company in Mumbai ,interior designer in mumbai ,top interior designers in mumbai ,
      best interior designers in mumbai ,residential interior designers in mumbai ,
      office interior designer in mumbai ,bungalow interior design ,office interior designer ,
      residential interior designers ,interior design firms ,interior company ,
      interior designing company ,best interior designing company ,best luxury interior designers ,
      best villa interior design"
        />
        <link rel="canonical" href="https://kehainterio.com/interior-company" />
      </MetaTags>

      {/* ======== Sco end ======= */}
      <section
        className="breadcrumb-area"
        style={{ backgroundImage: `url(${about2})` }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="inner-content clearfix">
                <div className="title">
                  <h1>Our Clients</h1>
                </div>
                {/* <div className="breadcrumb-menu float-right">
            <ul className="clearfix">
              <li>
                <a href="index.html">Home</a>
              </li>
              <li className="active">Account</li>
            </ul>
          </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="mt-5">
        <div className="container">
          <div className="sec-title text-center">
            <p>Corporate Clients</p>
            <div className="title">
              Various list <span>of Clients</span>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3 mt-5">
              <div className="logoimg">
                <img src={indo} />
              </div>
            </div>

            <div className="col-md-3 mt-5">
              <div className="logoimg">
                <img src={nsci} />
              </div>
            </div>
            <div className="col-md-3 mt-5">
              <div className="logoimg">
                <img src={Clientimages10} />
              </div>
            </div>
            <div className="col-md-3 mt-5">
              <div className="logoimg">
                <img src={Clientimages11} />
              </div>
            </div>
            <div className="col-md-3 mt-5">
              <div className="logoimg">
                <img src={Clientimages12} />
              </div>
            </div>
            <div className="col-md-3 mt-5">
              <div className="logoimg">
                <img src={Clientimages13} />
              </div>
            </div>
            <div className="col-md-3 mt-5">
              <div className="logoimg">
                <img src={Clientimages14} />
              </div>
            </div>
            <div className="col-md-3 mt-5">
              <div className="logoimg">
                <img src={Clientimages6} />
              </div>
            </div>
            <div className="col-md-3 mt-5">
              <div className="logoimg">
                <img src={Clientimages7} />
              </div>
            </div>
            <div className="col-md-3 mt-5">
              <div className="logoimg">
                <img src={Clientimages8} />
              </div>
            </div>
            <div className="col-md-3 mt-5">
              <div className="logoimg">
                <img src={Clientimages9} />
              </div>
            </div>
            <div className="col-md-3 mt-5">
              <div className="logoimg">
                <img src={intelnet} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Client;
