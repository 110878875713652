import React, { useState } from "react";
import about2 from "../Images/breadcrumb-bg.jpg";
import Footer from "../Component/Footer.js";
import "../Css/Completed.css";
import { Link } from "react-router-dom";
import Site from "../Component/Site";
import { MetaTags } from "react-meta-tags";

const Completedproject = () => {
  return (
    <>
      {/* ========= Sco ========== */}

      <MetaTags>
        <title>
          Elevate Your Space with the Top Interior Designer in Mumbai
        </title>
        <meta
          name="description"
          content="Discover the Epitome of Design Excellence. Transform Your Space with Mumbai's Leading Interior Designer. Unleash Unmatched Style Today!"
        />
        <meta
          name="google-site-verification"
          content="lQRenlykQgkhp3tuKDb9sjxgGzGVRUiKnbEwyj16bw0"
        />
        <meta
          property="og:title"
          content="Elevate Your Space with the Top Interior Designer in Mumbai"
        />
        <meta
          property="og:description"
          content="Discover the Epitome of Design Excellence. Transform Your Space with Mumbai's Leading Interior Designer. Unleash Unmatched Style Today!"
        />
        <meta
          name="keywords"
          content="Interior company in Mumbai ,interior designer in mumbai ,top interior designers in mumbai ,
      best interior designers in mumbai ,residential interior designers in mumbai ,
      office interior designer in mumbai ,bungalow interior design ,office interior designer ,
      residential interior designers ,interior design firms ,interior company ,
      interior designing company ,best interior designing company ,best luxury interior designers ,
      best villa interior design"
        />
        <link
          rel="canonical"
          href="https://kehainterio.com/best-interior-designers-in-mumbai"
        />
      </MetaTags>

      {/* ======== Sco end ======= */}
      <section
        className="breadcrumb-area"
        style={{ backgroundImage: `url(${about2})` }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="inner-content clearfix">
                <div className="title">
                  <h1>Completed Projects</h1>
                </div>
                {/* <div className="breadcrumb-menu float-right">
            <ul className="clearfix">
              <li>
                <a href="index.html">Home</a>
              </li>
              <li className="active">Account</li>
            </ul>
          </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="container">
        <div className="intimages">
          <div className="row">
            {Site.map((elem) => {
              const { id, EImage, EventName, Category, Location } = elem;
              return (
                <>
                  <div class="col-lg-4 col-md-6 col-12 trad" key={id}>
                    <Link to={`/Projects/${Category}`}>
                      <div class="single-project-style4">
                        <div class="img-holder">
                          <div class="inner">
                            <img src={EImage} alt="" />
                            <div class="overlay-box">
                              <div class="box">
                                <div class="link">
                                  <span class="icon-out"></span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="overlay-content">
                            <div class="title">
                              <span>{EventName}</span>
                              <h3>{Location} </h3>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </div>
      <div className="done-project mb-5">
        <div className="container">
          <div className="sec-title text-center">
            <p>Complete project</p>
            <div className="title">
              Our Completed<span> Project List</span>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="project-complete">
                <table>
                  <tr>
                    <th>
                      <h5>Sr No:</h5>
                    </th>
                    <th>
                      <h5>Description</h5>
                    </th>
                    <th>
                      <h5>Location</h5>
                    </th>
                    <th>
                      <h5>Aqare Feet Area</h5>
                    </th>
                  </tr>
                  <tr>
                    <td>
                      <h6>01</h6>
                    </td>
                    <td>
                      <h6>PMGP COLONY MHADA COLONY</h6>
                    </td>
                    <td>
                      <h6>MULUND-Mumbai</h6>
                    </td>
                    <td>
                      <h6>All Building</h6>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h6>02</h6>
                    </td>
                    <td>
                      <h6>NSCI CLUB</h6>
                    </td>
                    <td>
                      <h6>Worli-Mumbai</h6>
                    </td>
                    <td>
                      <h6>200000</h6>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h6>03</h6>
                    </td>
                    <td>
                      <h6>BrookField</h6>
                    </td>
                    <td>
                      <h6>One BKC</h6>
                    </td>
                    <td>
                      <h6>15000</h6>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h6>04</h6>
                    </td>
                    <td>
                      <h6>Sathe Collage</h6>
                    </td>
                    <td>
                      <h6>Vile Parle-Mumbai</h6>
                    </td>
                    <td>
                      <h6>All Building</h6>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h6>05</h6>
                    </td>
                    <td>
                      <h6>Rajaram Bapu Sahakri Bank</h6>
                    </td>
                    <td>
                      <h6>Hadapsar Pune</h6>
                    </td>
                    <td>
                      <h6>5000</h6>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h6>06</h6>
                    </td>
                    <td>
                      <h6>Matrix Partner</h6>
                    </td>
                    <td>
                      <h6>Worli-Mumbai</h6>
                    </td>
                    <td>
                      <h6>15000</h6>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h6>07</h6>
                    </td>
                    <td>
                      <h6>Sahara City</h6>
                    </td>
                    <td>
                      <h6>Lonavala</h6>
                    </td>
                    <td>
                      <h6>15000</h6>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h6>08</h6>
                    </td>
                    <td>
                      <h6>'D'Decor</h6>
                    </td>
                    <td>
                      <h6>Palghar</h6>
                    </td>
                    <td>
                      <h6>12000</h6>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h6>09</h6>
                    </td>
                    <td>
                      <h6>Rajaram Bapu Sahakari Bank</h6>
                    </td>
                    <td>
                      <h6>Dadar Mumbai</h6>
                    </td>
                    <td>
                      <h6>4500</h6>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h6>10</h6>
                    </td>
                    <td>
                      <h6>PanKaj Hotel</h6>
                    </td>
                    <td>
                      <h6>Karad MH</h6>
                    </td>
                    <td>
                      <h6>5000</h6>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h6>11</h6>
                    </td>
                    <td>
                      <h6>Utkarsh Bank</h6>
                    </td>
                    <td>
                      <h6>Chembur Mumbai</h6>
                    </td>
                    <td>
                      <h6>10000</h6>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h6>12</h6>
                    </td>
                    <td>
                      <h6>JTCPL DESIGN HEAD Office</h6>
                    </td>
                    <td>
                      <h6>Mumbai</h6>
                    </td>
                    <td>
                      <h6>4000</h6>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h6>13</h6>
                    </td>
                    <td>
                      <h6>Laxmi Vilas Bank Corporate Office</h6>
                    </td>
                    <td>
                      <h6>Dadar Mumbai</h6>
                    </td>
                    <td>
                      <h6>16000</h6>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h6>14</h6>
                    </td>
                    <td>
                      <h6>Axis Bank Corporate Office</h6>
                    </td>
                    <td>
                      <h6>Dadar Mumbai</h6>
                    </td>
                    <td>
                      <h6>20000</h6>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h6>15</h6>
                    </td>
                    <td>
                      <h6>Teleperfomance</h6>
                    </td>
                    <td>
                      <h6>Goregaon mumbai</h6>
                    </td>
                    <td>
                      <h6>25000</h6>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h6>16</h6>
                    </td>
                    <td>
                      <h6>DHL Global Forwarding Pvt Ltd</h6>
                    </td>
                    <td>
                      <h6>Surat</h6>
                    </td>
                    <td>
                      <h6>5000</h6>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h6>17</h6>
                    </td>
                    <td>
                      <h6>Kalyani Industries Pvt Ltd</h6>
                    </td>
                    <td>
                      <h6>Vikhroli</h6>
                    </td>
                    <td>
                      <h6>3000</h6>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h6>18</h6>
                    </td>
                    <td>
                      <h6>Wadhava W54 Mr.Khajanchi</h6>
                    </td>
                    <td>
                      <h6>Matunga Mumbai</h6>
                    </td>
                    <td>
                      <h6>5000</h6>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h6>19</h6>
                    </td>
                    <td>
                      <h6>Teleperformance</h6>
                    </td>
                    <td>
                      <h6>Malad Mumbai</h6>
                    </td>
                    <td>
                      <h6>5000</h6>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h6>20</h6>
                    </td>
                    <td>
                      <h6>Manudhar Realty Pvt Ltd</h6>
                    </td>
                    <td>
                      <h6>One BKC</h6>
                    </td>
                    <td>
                      <h6>2000</h6>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h6>21</h6>
                    </td>
                    <td>
                      <h6>Hiren Gandhi</h6>
                    </td>
                    <td>
                      <h6>Baroda</h6>
                    </td>
                    <td>
                      <h6>18000</h6>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h6>22</h6>
                    </td>
                    <td>
                      <h6>AXIs BANK CORPORATE OFFICE</h6>
                    </td>
                    <td>
                      <h6>PALAVA DOMBIVALI</h6>
                    </td>
                    <td>
                      <h6>30000</h6>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h6>23</h6>
                    </td>
                    <td>
                      <h6>FLEET MANAGEMENT PVT LTD</h6>
                    </td>
                    <td>
                      <h6>ANDHERI EAST</h6>
                    </td>
                    <td>
                      <h6>15000</h6>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <h6>24</h6>
                    </td>
                    <td>
                      <h6>NIELSEN IQ </h6>
                    </td>
                    <td>
                      <h6>PUNE</h6>
                    </td>
                    <td>
                      <h6> 50000 sft</h6>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h6>25</h6>
                    </td>
                    <td>
                      <h6>BEARING PRIVATE EQUITY ASIA</h6>
                    </td>
                    <td>
                      <h6>LOWER PAREL</h6>
                    </td>
                    <td>
                      <h6>30000 sft</h6>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h6>26</h6>
                    </td>
                    <td>
                      <h6>KEVA FRAGRANCES PVT LTD</h6>
                    </td>
                    <td>
                      <h6>MIDC ANDHERI </h6>
                    </td>
                    <td>
                      <h6>6000 sft</h6>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <h6>27</h6>
                    </td>
                    <td>
                      <h6>CONNECTWELL INDUSTRIES PVT LTD</h6>
                    </td>
                    <td>
                      <h6>DOMBIWALI</h6>
                    </td>
                    <td>
                      <h6>35000 sft</h6>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h6>28</h6>
                    </td>
                    <td>
                      <h6>INDOCOUNT INDUSTRIES PVT LTD</h6>
                    </td>
                    <td>
                      <h6>ANDHERI EAST</h6>
                    </td>
                    <td>
                      <h6>6000 sft</h6>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h6>29</h6>
                    </td>
                    <td>
                      <h6>SK MOBICARE (Kohinoor Square)</h6>
                    </td>
                    <td>
                      <h6>Dadar</h6>
                    </td>
                    <td>
                      <h6> 2500 sft</h6>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h6>30</h6>
                    </td>
                    <td>
                      <h6>INDIGRID</h6>
                    </td>
                    <td>
                      <h6>Kalina</h6>
                    </td>
                    <td>
                      <h6>10000 sft</h6>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h6>31</h6>
                    </td>
                    <td>
                      <h6>RESOLUTE PERTNER</h6>
                    </td>
                    <td>
                      <h6>BKC</h6>
                    </td>
                    <td>
                      <h6>3500 sft</h6>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h6>32</h6>
                    </td>
                    <td>
                      <h6>TOUCH STONE </h6>
                    </td>
                    <td>
                      <h6>BKC</h6>
                    </td>
                    <td>
                      <h6> 3000 sft</h6>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Completedproject;
