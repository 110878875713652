import React, { useState } from "react";
import about2 from "../Images/breadcrumb-bg.jpg";
import Footer from "../Component/Footer.js";
import "../Css/Completed.css";
import Ongoing1 from "../Images/ongoing/google.jpg";
import google1 from "../Images/ongoing/g1.jpg";

import google2 from "../Images/ongoing/g2.jpg";

import google3 from "../Images/ongoing/g3.jpg";

import google4 from "../Images/ongoing/g4.jpg";

import google5 from "../Images/ongoing/g5.jpg";
import google6 from "../Images/ongoing/g6.jpg";

import Ongoing2 from "../Images/brands/image5.jpeg";
import res1 from "../Images/ongoing/ar1.jpg";
import res2 from "../Images/ongoing/ar10.jpg";
import res3 from "../Images/ongoing/ar11.jpg";
import res4 from "../Images/ongoing/ar9.jpg";
import res5 from "../Images/ongoing/ar8.jpg";
import res6 from "../Images/ongoing/ar7.jpg";
import Ongoing3 from "../Images/brands/image6.jpeg";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay, Pagination } from "swiper";
import { MetaTags } from "react-meta-tags";

const Ongoingproject = () => {
  return (
    <>
      {/* ========= Sco ========== */}

      <MetaTags>
        <title>
          Elevate Your Space with the Top Interior Designer in Mumbai
        </title>
        <meta
          name="description"
          content="Discover the Epitome of Design Excellence. Transform Your Space with Mumbai's Leading Interior Designer. Unleash Unmatched Style Today!"
        />
        <meta
          name="google-site-verification"
          content="lQRenlykQgkhp3tuKDb9sjxgGzGVRUiKnbEwyj16bw0"
        />
        <meta
          property="og:title"
          content="Elevate Your Space with the Top Interior Designer in Mumbai"
        />
        <meta
          property="og:description"
          content="Discover the Epitome of Design Excellence. Transform Your Space with Mumbai's Leading Interior Designer. Unleash Unmatched Style Today!"
        />
        <meta
          name="keywords"
          content="Interior company in Mumbai ,interior designer in mumbai ,top interior designers in mumbai ,
      best interior designers in mumbai ,residential interior designers in mumbai ,
      office interior designer in mumbai ,bungalow interior design ,office interior designer ,
      residential interior designers ,interior design firms ,interior company ,
      interior designing company ,best interior designing company ,best luxury interior designers ,
      best villa interior design"
        />
        <link rel="canonical" href="https://kehainterio.com/residential-interior-designers" />
      </MetaTags>

      {/* ======== Sco end ======= */}
      <section
        className="breadcrumb-area"
        style={{ backgroundImage: `url(${about2})` }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="inner-content clearfix">
                <div className="title">
                  <h1>Ongoing Projects</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="container">
        <div className="intimages">
          <div className="row">
            <div class="col-lg-4 col-md-6 col-12 trad">
              <Swiper
                direction={"horizontal"}
                pagination={{
                  clickable: true,
                }}
                autoplay={{
                  delay: 3000,
                  disableOnInteraction: false,
                }}
                modules={[Autoplay, Pagination]}
                className="mySwiper"
              >
                <SwiperSlide>
                  <div class="single-project-style4">
                    <div class="img-holder">
                      <div class="inner">
                        <img src={Ongoing1} alt="" />
                        <div class="overlay-box">
                          <div class="box">
                            <div class="link">
                              <span class="icon-out"></span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="overlay-content">
                        <div class="title">
                          <span>Modern Design</span>
                          <h3>Google India Pvt Ltd.</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div class="single-project-style4">
                    <div class="img-holder">
                      <div class="inner">
                        <img src={google1} alt="" />
                        <div class="overlay-box">
                          <div class="box">
                            <div class="link">
                              <span class="icon-out"></span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="overlay-content">
                        <div class="title">
                          <span>Modern Design</span>
                          <h3>Google India Pvt Ltd.</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div class="single-project-style4">
                    <div class="img-holder">
                      <div class="inner">
                        <img src={google2} alt="" />
                        <div class="overlay-box">
                          <div class="box">
                            <div class="link">
                              <span class="icon-out"></span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="overlay-content">
                        <div class="title">
                          <span>Modern Design</span>
                          <h3>Google India Pvt Ltd.</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>{" "}
                <SwiperSlide>
                  <div class="single-project-style4">
                    <div class="img-holder">
                      <div class="inner">
                        <img src={google3} alt="" />
                        <div class="overlay-box">
                          <div class="box">
                            <div class="link">
                              <span class="icon-out"></span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="overlay-content">
                        <div class="title">
                          <span>Modern Design</span>
                          <h3>Google India Pvt Ltd.</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div class="single-project-style4">
                    <div class="img-holder">
                      <div class="inner">
                        <img src={google4} alt="" />
                        <div class="overlay-box">
                          <div class="box">
                            <div class="link">
                              <span class="icon-out"></span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="overlay-content">
                        <div class="title">
                          <span>Modern Design</span>
                          <h3>Google India Pvt Ltd.</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div class="single-project-style4">
                    <div class="img-holder">
                      <div class="inner">
                        <img src={google5} alt="" />
                        <div class="overlay-box">
                          <div class="box">
                            <div class="link">
                              <span class="icon-out"></span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="overlay-content">
                        <div class="title">
                          <span>Modern Design</span>
                          <h3>Google India Pvt Ltd.</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div class="single-project-style4">
                    <div class="img-holder">
                      <div class="inner">
                        <img src={google6} alt="" />
                        <div class="overlay-box">
                          <div class="box">
                            <div class="link">
                              <span class="icon-out"></span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="overlay-content">
                        <div class="title">
                          <span>Modern Design</span>
                          <h3>Google India Pvt Ltd.</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
            <div class="col-lg-4 col-md-6 col-12 trad">
              <Swiper
                direction={"horizontal"}
                pagination={{
                  clickable: true,
                }}
                autoplay={{
                  delay: 3000,
                  disableOnInteraction: false,
                }}
                modules={[Autoplay, Pagination]}
                className="mySwiper"
              >
                <SwiperSlide>
                  <div class="single-project-style4">
                    <div class="img-holder">
                      <div class="inner">
                        <img src={Ongoing2} alt="" />
                        <div class="overlay-box">
                          <div class="box">
                            <div class="link">
                              <span class="icon-out"></span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="overlay-content">
                        <div class="title">
                          <span> Hande Residency </span>
                          <h3>Dadar</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div class="single-project-style4">
                    <div class="img-holder">
                      <div class="inner">
                        <img src={res2} alt="" />
                        <div class="overlay-box">
                          <div class="box">
                            <div class="link">
                              <span class="icon-out"></span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="overlay-content">
                        <div class="title">
                          <span> Hande Residency </span>
                          <h3>Dadar</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div class="single-project-style4">
                    <div class="img-holder">
                      <div class="inner">
                        <img src={res3} alt="" />
                        <div class="overlay-box">
                          <div class="box">
                            <div class="link">
                              <span class="icon-out"></span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="overlay-content">
                        <div class="title">
                          <span> Hande Residency </span>
                          <h3>Dadar</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div class="single-project-style4">
                    <div class="img-holder">
                      <div class="inner">
                        <img src={res4} alt="" />
                        <div class="overlay-box">
                          <div class="box">
                            <div class="link">
                              <span class="icon-out"></span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="overlay-content">
                        <div class="title">
                          <span> Hande Residency </span>
                          <h3>Dadar</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div class="single-project-style4">
                    <div class="img-holder">
                      <div class="inner">
                        <img src={res5} alt="" />
                        <div class="overlay-box">
                          <div class="box">
                            <div class="link">
                              <span class="icon-out"></span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="overlay-content">
                        <div class="title">
                          <span> Hande Residency </span>
                          <h3>Dadar</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div class="single-project-style4">
                    <div class="img-holder">
                      <div class="inner">
                        <img src={res6} alt="" />
                        <div class="overlay-box">
                          <div class="box">
                            <div class="link">
                              <span class="icon-out"></span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="overlay-content">
                        <div class="title">
                          <span> Hande Residency </span>
                          <h3>Dadar</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div class="single-project-style4">
                    <div class="img-holder">
                      <div class="inner">
                        <img src={res1} alt="" />
                        <div class="overlay-box">
                          <div class="box">
                            <div class="link">
                              <span class="icon-out"></span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="overlay-content">
                        <div class="title">
                          <span> Hande Residency </span>
                          <h3>Dadar</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
            <div class="col-lg-4 col-md-6 col-12 trad">
              <div class="single-project-style4">
                <div class="img-holder">
                  <div class="inner">
                    <img src={Ongoing3} alt="" />
                    <div class="overlay-box">
                      <div class="box">
                        <div class="link">
                          <span class="icon-out"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="overlay-content">
                    <div class="title">
                      <span>Modern Design</span>
                      <h3>Indocount</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Ongoingproject;
