import React, { useState } from "react";
import about2 from "../Images/breadcrumb-bg.jpg";
import Footer from "../Component/Footer.js";
import "../Css/Completed.css";
import Upcoming from'../Images/upcoming.jpg';
import { MetaTags } from "react-meta-tags";

const Upcomingproject = () => {
  return (
    <>
      {/* ========= Sco ========== */}

      <MetaTags>
        <title>
        Elevate Your Space with the Top Interior Designer in Mumbai
        </title>
        <meta
          name="description"
          content="Discover the Epitome of Design Excellence. Transform Your Space with Mumbai's Leading Interior Designer. Unleash Unmatched Style Today!"
        />
        <meta
          name="google-site-verification"
          content="lQRenlykQgkhp3tuKDb9sjxgGzGVRUiKnbEwyj16bw0"
        />
        <meta
          property="og:title"
          content="Elevate Your Space with the Top Interior Designer in Mumbai"
        />
        <meta
          property="og:description"
          content="Discover the Epitome of Design Excellence. Transform Your Space with Mumbai's Leading Interior Designer. Unleash Unmatched Style Today!"
        />
        <meta
          name="keywords"
          content="Interior company in Mumbai ,interior designer in mumbai ,top interior designers in mumbai ,
      best interior designers in mumbai ,residential interior designers in mumbai ,
      office interior designer in mumbai ,bungalow interior design ,office interior designer ,
      residential interior designers ,interior design firms ,interior company ,
      interior designing company ,best interior designing company ,best luxury interior designers ,
      best villa interior design"
        />
        <link rel="canonical" href="https://kehainterio.com/best-luxury-interior-designers" />
      </MetaTags>

      {/* ======== Sco end ======= */}
      <section
        className="breadcrumb-area"
        style={{ backgroundImage: `url(${about2})` }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="inner-content clearfix">
                <div className="title">
                  <h1>Upcoming Projects</h1>
                </div>
                {/* <div className="breadcrumb-menu float-right">
            <ul className="clearfix">
              <li>
                <a href="index.html">Home</a>
              </li>
              <li className="active">Account</li>
            </ul>
          </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="container">
        <div className="intimages">
          <div className="row">
            <div class="col-lg-4 col-md-6 col-12 trad">
              <div class="single-project-style4">
                <div class="img-holder">
                  <div class="inner">
                    <img src={Upcoming} alt="" />
                    <div class="overlay-box">
                      <div class="box">
                        <div class="link">
                          <span class="icon-out"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="overlay-content">
                    <div class="title">
                      <span>Comming Soon</span>
                      <h3> Upcoming Projects</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-12 trad">
              <div class="single-project-style4">
                <div class="img-holder">
                  <div class="inner">
                    <img src={Upcoming} alt="" />
                    <div class="overlay-box">
                      <div class="box">
                        <div class="link">
                          <span class="icon-out"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="overlay-content">
                    <div class="title">
                      <span>Comming Soon</span>
                      <h3> Upcoming Projects</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-12 trad">
              <div class="single-project-style4">
                <div class="img-holder">
                  <div class="inner">
                    <img src={Upcoming} alt="" />
                    <div class="overlay-box">
                      <div class="box">
                        <div class="link">
                          <span class="icon-out"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="overlay-content">
                    <div class="title">
                      <span>Comming Soon</span>
                      <h3> Upcoming Projects</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Upcomingproject;
