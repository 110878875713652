import React from "react";
import about2 from "../Images/breadcrumb-bg.jpg";
import "../Css/About.css";
import Footer from "../Component/Footer.js";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper";
import visionImages from "../Images/gallery/passage1.jpg";
import { MetaTags } from "react-meta-tags";

const Vision = () => {
  return (
    <>
      {/* ========= Sco ========== */}

      <MetaTags>
        <title>
        Elevate Your Space with the Top Interior Designer in Mumbai
        </title>
        <meta
          name="description"
          content="Discover the Epitome of Design Excellence. Transform Your Space with Mumbai's Leading Interior Designer. Unleash Unmatched Style Today!"
        />
        <meta
          name="google-site-verification"
          content="lQRenlykQgkhp3tuKDb9sjxgGzGVRUiKnbEwyj16bw0"
        />
        <meta
          property="og:title"
          content="Elevate Your Space with the Top Interior Designer in Mumbai"
        />
        <meta
          property="og:description"
          content="Discover the Epitome of Design Excellence. Transform Your Space with Mumbai's Leading Interior Designer. Unleash Unmatched Style Today!"
        />
        <meta
          name="keywords"
          content="Interior company in Mumbai ,interior designer in mumbai ,top interior designers in mumbai ,
      best interior designers in mumbai ,residential interior designers in mumbai ,
      office interior designer in mumbai ,bungalow interior design ,office interior designer ,
      residential interior designers ,interior design firms ,interior company ,
      interior designing company ,best interior designing company ,best luxury interior designers ,
      best villa interior design"
        />
        <link rel="canonical" href="https://kehainterio.com/interior-contractor-and-designer" />
      </MetaTags>

      {/* ======== Sco end ======= */}
      {/* ///////////////////////////////////////////////////////// */}

      <section
        className="breadcrumb-area"
        style={{ backgroundImage: `url(${about2})` }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="inner-content clearfix">
                <div className="title">
                  <h1>
                    Talented Interior contractor &
                    <br /> Designer.
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="mt-5">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <Swiper
                // slidesPerView={3}
                // spaceBetween={30}
                pagination={{
                  clickable: true,
                }}
                modules={[Pagination]}
                className="Swiper"
              >
                <SwiperSlide>
                  <div className="img-box">
                    <div className="inner">
                      <img src={visionImages} alt=" " />
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="img-box">
                    <div className="inner">
                      <img src={visionImages} alt=" " />
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="img-box">
                    <div className="inner">
                      <img src={visionImages} alt=" " />
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
            <div className="col-md-6">
              <div className="text-box">
                <div className="inner">
                  <div className="date">
                    <h3>Our Vision</h3>
                  </div>
                  <div className="text">
                    <p>
                      Our commitment to innovation and excellence invariably
                      results in a successfully completion of Projects for our
                      clients. We understand and promote the idea of working as
                      a partnership with our clients to ensure their goals are
                      met.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ============ */}
      <div className="fact-counter">
        <div className="row">
          {/*Start Single Fact Counter*/}
          <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
            <div
              className="single-fact-counter wow fadeInLeft"
              data-wow-delay="100ms"
              data-wow-duration="1500ms"
              style={{ visibility: "visible" }}
            >
              <div className="count-box counted">
                <h1>
                  <span
                    className="timer"
                    data-from={1}
                    data-to={36}
                    data-speed={5000}
                    data-refresh-interval={50}
                  >
                    20
                  </span>
                </h1>
              </div>
              <div className="title">
                <h3>
                  Years of
                  <br /> Experience
                </h3>
              </div>
            </div>
          </div>
          {/*End Single Fact Counter*/}
          {/*Start Single Fact Counter*/}
          <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
            <div
              className="single-fact-counter wow fadeInLeft"
              data-wow-delay="200ms"
              data-wow-duration="1500ms"
              style={{ visibility: "visible" }}
            >
              <div className="count-box counted">
                <h1>
                  <span
                    className="timer"
                    data-from={1}
                    data-to={5}
                    data-speed={5000}
                    data-refresh-interval={50}
                  >
                    04+
                  </span>
                  {/* <img src="images/icon/k.png" alt="" /> */}
                </h1>
              </div>
              <div className="title">
                <h3>
                  Ongoing
                  <br />
                  Projects
                </h3>
              </div>
            </div>
          </div>
          {/*End Single Fact Counter*/}
          {/*Start Single Fact Counter*/}
          <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
            <div
              className="single-fact-counter wow fadeInLeft"
              data-wow-delay="300ms"
              data-wow-duration="1500ms"
              style={{ visibility: "visible" }}
            >
              <div className="count-box counted">
                <h1>
                  <span
                    className="timer"
                    data-from={1}
                    data-to={24}
                    data-speed={5000}
                    data-refresh-interval={50}
                  >
                    85+
                  </span>
                </h1>
              </div>
              <div className="title">
                <h3>
                  Projects
                  <br /> Completed
                </h3>
              </div>
            </div>
          </div>
          {/*End Single Fact Counter*/}
          {/*Start Single Fact Counter*/}
          <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
            <div
              className="single-fact-counter wow fadeInLeft"
              data-wow-delay="400ms"
              data-wow-duration="1500ms"
              style={{ visibility: "visible" }}
            >
              <div className="count-box counted">
                <h1>
                  <span
                    className="timer"
                    data-from={1}
                    data-to={47}
                    data-speed={5000}
                    data-refresh-interval={50}
                  >
                    05+
                  </span>
                </h1>
              </div>
              <div className="title">
                <h3>
                  Upcoming
                  <br /> Projects
                </h3>
              </div>
            </div>
          </div>
          {/*End Single Fact Counter*/}
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Vision;
